import React from 'react';
import AboutImg from "../images/about.jpg";
import AboutImg2 from "../images/about2.jpg";
import AboutImg3 from "../images/about3.jpg";
import VaneCV from "../assets/dilshadansaricv.pdf";
import { UilDownloadAlt } from '@iconscout/react-unicons'

function About() {
    return (
        <section className="about section" id="about">
            <h2 className="section__title">About Me</h2>
            <span className="section__subtitle">Let me introduce myself</span>

            <div className="about__container container">

                <div className="about__data">
                    <p className="about__description">
                        Hello my name is Dilshad and I am a HubSpot and customer service expert. I have worked in the industry for over 4 years and have helped businesses from small startups to large enterprises market themselves, increase their sales and team productivity, deliver exceptional customer service satisfaction levels and ultimately grow their businesses leveraging the power of HubSpot. 
                    </p>
                    <p className="about__description">
                        
                        I have over a dozen certifications and have been helping businesses with inbound marketing over the years. I got into inbound marketing because I love helping businesses grow. I love seeing the lightbulb moment when they finally "get it", start seeing results from their inbound marketing efforts, and at the same time it's so gratifying to know that somewhere I contributed to make that happen. 
                
                        I'm also passionate about excellent customer service. I believe that happy customers are the key to any successful business. I love going above and beyond to help solve customers' problems and make their experience with a company the best it can be.
                    </p>
                    <p className="about__description">
                        As a certified HubSpot trainer, I have worked with companies of all sizes to help them implement HubSpot, improve their marketing, sales, and customer service, and give exceptional service to their customers as a result.
                    </p> 
                </div>
                
            </div>
        </section>
    )
}

export default About
