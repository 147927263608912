import React from "react";
import {
  UilInstagram,
  UilLinkedinAlt
} from "@iconscout/react-unicons";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__bg">
        <div className="footer__container container grid">
          <div>
            <h1 className="footer__title"><a href="#home" className="footer__title">
            Dilshad 
            </a>
            </h1>
            <span className="footer__subtitle">HubSpot Expert, and Customer Service Professional</span>
          </div>

          <ul className="footer__links">
            <li>
              <a href="#about" className="footer__link">
                About
              </a>
            </li>

            <li>
              <a href="#contact" className="footer__link">
                Contact
              </a>
            </li>
          </ul>

          <div className="footer__socials">
            <a
              href="https://www.linkedin.com/in/dilshad-ansari"
              target="_blank"
              rel="noreferrer"
              className="footer__social"
            >
              <UilLinkedinAlt className="" />
            </a>
            <a
              href="https://www.instagram.com/ansaridilshad446/"
              target="_blank"
              rel="noreferrer"
              className="footer__social"
            >
              <UilInstagram className="" />
            </a>
          </div>
        </div>

        <p className="footer__copy">Made with ❤️ by Dilshad&#174; All right reserved</p>
      </div>
    </footer>
  );
}

export default Footer;
