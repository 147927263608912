import React, { Fragment } from 'react';
import Navbar from './components/navbar/Navbar';
import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Footer from './components/footer/Footer';
import ScrollTop from './components/scroll/ScrollTop';

function App() {
    
    return (
        <Fragment>
            <main className="main">
                <Navbar />
                <Home />
                <About />
                <Contact />
            </main>
            <Footer />
            <ScrollTop />
        </Fragment>
    )
}

export default App
